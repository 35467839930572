import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Manuja DeSilva',
    component: Home
  },
  {
    path: '/apply',
    name: 'Build The Future',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Apply.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

/*
* Set document title to route name before route update 
*/
router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
})

export default router
