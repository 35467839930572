const Content = {
    showcase: {
        manos: {
            heading: "Manos: A Benchmarking tool for RocksDB",
            text: "A CLI tool for easily benchmarking RocksDB, a fast and efficient in-memory database. Simulate workloads, tune various RocksDB parameters, run individual and aggregated experiments, and write your own experiments.",
            link: "https://github.com/mdesilva/Manos"
        },
        debs: {
            heading: "DEBS: Inferring Energy Consumption",
            text: "Solution to the ACM DEBS 2020 Grand Challenge. Using Apache Flink and a sequential clustering-based event detection method, detect and output energy consumption events by analyzing a potentially unbounded stream of voltage and current data.",
            link: "https://desilva.codes/files/GrandChallengePaper.pdf"
        },
        agriworks: {
            heading: "Agriworks: Agricultural Data Analysis",
            text: "Lead engineer of Agriworks, a project built by Hack4Impact at Boston University. Agriworks provides international farmers, data scientists, and policymakers a platform to analyze agricultural data in order to make better agricultural policy decisions. ",
            link: "https://agri-works.org/"
        },
        docsend: {
            heading: "Docsend Slide Downloader",
            text: "A serverless, lightweight Chrome extension for downloading pitch decks from Docsend. Originally built in one March weekend to help out a friend frustrated by the lack of ability to quickly download slide decks without compromising privacy and security.",
            link: "https://chrome.google.com/webstore/detail/docsend-slide-deck-downlo/bdapagnogpjnojnencohaeeeedhoncjf"
        },
        messenger: {
            heading: "Messenger App",
            text: "A iOS and Android messaging application prototype. The application utilizes sockets, tokens, and React Native as its core components for a basic messaging application with authentication. Built for the Spring 2020 Hack4Impact bootcamp at Boston University. ",
            link: "https://github.com/mdesilva/whatsapplite-server"
        }
    }
}

export default Content;