<template>
<div id="carousel-controls">
    <svg id="prev" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="prev"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>           
    <div id="carousel-container">
        <div id="carousel"></div>
    </div>
    <svg id="next" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
</div>

</template>

<style>
.carousel-controls {
    display: flex;
    visibility: hidden;
}

#carousel-container {
    width: 210px;
    height: 140px;
    position: relative;
    perspective: 1000px;
    margin-left: 5rem;
    margin-right: 5rem;
}

.carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 1s;
}

.next, .prev {
    align-self: center;
}

.slide {
    position: absolute;
    width: 190px;
    height: 120px;
    left: 10px;
    top: 10px;
    background-size: cover;
    border-radius: 10px;
}
</style>

<script>
import Carousel from "../carousel"

export default {
    name: "Carousel",
    props: {
        images: Array,
        events: Array,
        interval: Number,
        autoplay: Boolean,
        pauseElements: Array
    },
    mounted() {
        if (this.$props.images.length < 3 || this.$props.images == null) {
            console.error("Carousel must have at least 3 images to run.");
        } else {
            const events = this.$props.events || null;
            const interval = this.$props.interval || null;
            const autoplay = this.$props.autoplay || null;
            const pause_elements = this.$props.pauseElements || null;
            new Carousel(this,this.$props.images, events, interval, autoplay, pause_elements);
        }
    }
}
</script>