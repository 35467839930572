class Carousel{

    constructor(vue, slide_sources, events, interval, autoplay, pause_elements) {
        this.vue_ = vue;
        this.num_slides_ = slide_sources.length;
        if (this.num_slides_ < 3 ) {
            console.error("Must have at least 3 slide sources (images) for Carousel.")
            return;
        }
        if (events != null && events.length == this.num_slides_) {
            this.events_ = events;
        }
        this.slide_sources_ = slide_sources;
        this.slide_width = 210; //default width of 210px
        this.y_axis_diff_ = 360 / this.num_slides_; 
        this.z_axis_offset = (this.slide_width / 2) / Math.tan((this.y_axis_diff_ / 2) * Math.PI/180);
        this.slide_interval_ = interval != null ? interval : 3000; //default interval of 3000ms
        this.carousel_div_ = document.getElementById("carousel");
        this.current_carousel_y_offset = 0; 
        this.current_slide_ = 0; //index referring to slide_sources
        this.hash_ = this.CreateRandomHash(); //each Carousel should have a unique hash to differentiate itself from other Carousels on the same page
        this.autoplay_enabled_ = autoplay == null ? true : autoplay;
        this.pause_elements_ = pause_elements; //When hovering over these elements, autoplay will pause if enabled. When mouseout, play is resumed.
        this.autoplay_interval_timer; //Interval timer for autoplay function
        this.next_btn_;
        this.prev_btn_;
        this.SetupElements();
        this.SetupListeners();
        this.CreateSlides();
        this.Autoplay();
    }

    CreateSlides() {
        let curr_y_diff = 0;
        for (let i =0; i < this.num_slides_; i++) {
            const slide = document.createElement("div");
            slide.classList.add("slide");
            slide.style.transform = `rotateY(${curr_y_diff}deg) translateZ(${this.z_axis_offset}px)`;
            slide.style.backgroundRepeat = "none";
            slide.style.backgroundImage = 'url(' + require('@/assets/' + this.slide_sources_[i]) + ')';
            curr_y_diff = curr_y_diff + this.y_axis_diff_;         
            this.carousel_div_.appendChild(slide);
        }
    }

    NextSlide(){
        this.current_slide_ = (this.current_slide_ + 1) % this.num_slides_ ;
        this.current_carousel_y_offset-=this.y_axis_diff_;
        this.carousel_div_.style.transform = `rotateY(${this.current_carousel_y_offset}deg)`;
        this.DispatchEvent();
    }

    PrevSlide(){
        if (this.current_slide_ == 0) {
            this.current_slide_ = (this.num_slides_ - 1) % this.num_slides_;
        } else {
            this.current_slide_ = (this.current_slide_ - 1) % this.num_slides_ ;
        }
        this.current_carousel_y_offset+=this.y_axis_diff_;
        this.carousel_div_.style.transform = `rotateY(${this.current_carousel_y_offset}deg)`;
        this.DispatchEvent();
    }

    SetupListeners(){
        this.next_btn_.addEventListener("click", () =>{
            this.NextSlide();
        })
        this.prev_btn_.addEventListener("click", () => {
            this.PrevSlide();
        })
        if (this.autoplay_enabled_) {
            document.getElementById(`carousel-controls[${this.hash_}]`).addEventListener("mouseover", () =>{
                this.PauseAutoplay();
            })
            document.getElementById(`carousel-controls[${this.hash_}]`).addEventListener("mouseleave", () =>{
                this.Autoplay();
            })
            if (this.pause_elements_ && this.pause_elements_.length != 0) {
                for(let i =0; i<this.pause_elements_.length; i++) {
                    document.getElementById(this.pause_elements_[i]).addEventListener('mouseover', () => {
                        this.PauseAutoplay();
                    })
                    document.getElementById(this.pause_elements_[i]).addEventListener('mouseleave', () => {
                        this.Autoplay();
                    })
                }
            }
        }
    }

    SetupElements() {
        //add hash to carousel, which will hold all slides of the carousel
        this.carousel_div_.setAttribute("id", `carousel[${this.hash_}]`);
        this.carousel_div_.classList.add("carousel");
        this.carousel_div = document.getElementById(`carousel[${this.hash}]`);

        //add hash to buttons 
        const next_btn = document.getElementById("next");
        const prev_btn = document.getElementById("prev");
        next_btn.setAttribute("id", `next[${this.hash_}]`);
        next_btn.classList.add("next");
        prev_btn.setAttribute("id", `prev[${this.hash_}]`);
        prev_btn.classList.add("prev");
        this.next_btn_ = document.getElementById(`next[${this.hash_}]`);
        this.prev_btn_ = document.getElementById(`prev[${this.hash_}]`);

        //add hash to container for carousel and buttons. Utilize this container to halt carousel on mouseover events, and start carousel again on mouseleave events.
        const carousel_controls = document.getElementById("carousel-controls");
        carousel_controls.setAttribute("id", `carousel-controls[${this.hash_}]`);
        carousel_controls.classList.add("carousel-controls");
        carousel_controls.style.visibility = "visible";
    }

    /*
    * @return 5 character alphabetical hash
    */
    CreateRandomHash() {
        let hash = "";
        for (let i =0; i < 5; i++) {
            hash += String.fromCharCode(((Math.floor(Math.random() * 25)) + 97))
        }
        return hash;
    }

    Autoplay(){
        if (this.autoplay_enabled_) {
            this.autoplay_interval_timer = setInterval(() => { this.NextSlide();}, this.slide_interval_);
        }
    }

    PauseAutoplay() {
        if (this.autoplay_enabled_) {
            clearInterval(this.autoplay_interval_timer);
        }
    }

    DispatchEvent() {
        if (this.events_) {
            this.vue_.$emit('carousel-event', this.events_[this.current_slide_]);
        }
    }
}

export default Carousel;