<template>
<div>
<div id="initial-viewport-container">
      <video autoplay loop muted playsinline src="../assets/starry-4k.mp4" id="parallax-video" poster></video>
      <v-container id="parallax-content">
        <v-row>
          <h1> Manuja DeSilva </h1>
        </v-row>
        <v-row style="margin-top:0.5rem;">
          <h3> 
            <div v-if="windowWidth < 700">
             Software Engineer. <br> Data Science Enthusiast. <br>  Hardware Tinkerer. <br> Writer.
            </div>
            <div v-else>
              Software Engineer. Data Science Enthusiast. Hardware Tinkerer. Writer.
            </div>
          </h3>
        </v-row>
        <v-row style="margin-top:1rem;">
          <ContactIcons/>
        </v-row>
      </v-container>
</div>
<div id="wrapper">
  <div id="wrapper-margin">
    <section id="projects">
    <h1> Cool Projects </h1>
    <div id="showcase">
      <div id="showcase-text"> 
        <a :href="showcaseText.link" target="_window"><h1> {{ showcaseText.heading }} </h1></a>
        <p> {{ showcaseText.text }} </p>
      </div>
      <Carousel 
        v-bind:images='this.portfolioImages' 
        v-bind:events='this.carouselEvents'
        v-bind:interval='6000'
        v-on:carousel-event="carouselEventReceived"
        v-bind:pauseElements="['showcase-text']"/>
    </div>
    </section>
  </div>
</div>
<div id="footer">
  <h3> Interested in working together ?
    <a class="contact-link" href="mailto:mdesilva@bu.edu"> Contact Me </a> 
  </h3>
</div>
</div>
</template>

<script>
import ContactIcons from "../components/ContactIcons";
import Carousel from "../components/Carousel"
import Content from "../assets/content"

export default {
  name: 'Home',
  components: {
    ContactIcons,
    Carousel
  },
  data() {
    return {
      windowHeight:window.innerHeight,
      windowWidth: window.innerWidth,
      portfolioImages: ["showcase/manos.jpg", "showcase/debs-alternate.jpg", "showcase/agriworks-alternate.jpg", "showcase/docsend.jpg", "showcase/messenger.jpg"],
      carouselEvents: ["manos", "debs", "agriworks", "docsend", "messenger"],
      showcaseText: {
        heading: Content.showcase.manos.heading,
        text: Content.showcase.manos.text,
        link: Content.showcase.manos.link
      }
    }
  },
  methods: {
    carouselEventReceived(data) {
      this.showcaseText.heading = Content.showcase[data].heading;
      this.showcaseText.text = Content.showcase[data].text;
      this.showcaseText.link = Content.showcase[data].link;
    }
  },
  mounted() {
    if (this.$route.fullPath == "/#projects") {
      window.scrollTo(0, window.innerHeight);
    }
  }
};
</script>

<style scoped>
#initial-viewport-container {
  height: 100vh;
  max-width: 100%;
  min-height: 500px;
  background-attachment: fixed;
  background-size:cover;
  background-position: top center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  padding-bottom: 50px;
  padding-top: 50px;
}

#parallax-content {
  position: absolute;
  color:white;
  bottom: 26.3%;
  left: 6.66%;
}

#parallax-content h1 {
  font-family: "Exo", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #3f3;
  font-size: 3rem;
  font-style: italic;
  font-weight: 800;
}

#parallax-content h3 {
  font-family: "Lora", sans-serif;
  font-weight: 700;
}

#parallax-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  top: 0;
  z-index: -1;
}

#wrapper {
  height: fit-content;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 6px solid #ff8100;
}

#wrapper-margin {
  margin-left: 10%;
  margin-right: 10%;
}

#projects > h1{
  text-align: center;
  font-size: 3rem;
  color: #2f334e;
  font-family: 'Exo', sans-serif;
  font-style: italic;
  font-weight: 800;
}

#showcase {
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
  margin-bottom: 3rem;
}

#showcase-text h1 {
  padding-bottom: 1rem;
}
#showcase-text p {
  margin-right: 4rem;
  font-family: 'Mukta', sans-serif;
  font-size: 18px;
}

#footer {
    font-family: 'Exo', sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 800;
    background-color: #32334e;
    color: white;
    padding: 0.75rem;
    text-align: center;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px -2px 5px 0px #00BCD4;
}

a.contact-link {
  color: #32ff32;
}

a {
  text-decoration: none;
  color: inherit;
}
@media only screen and (max-width: 700px){
  #parallax-content {
    position: absolute;
    bottom: 90px;
  }

  #parallax-content h1 {
    font-size: 2.5rem;
  }

  #showcase {
    flex-direction: column-reverse;
    align-items: center;
  }

  #showcase-text p {
    margin-right: 0rem;
  }

  #showcase-text h1 {
    margin-top: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }

  #footer {
    font-size: 12px;
  }
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

</style>
